<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon-VIP">
      <g id="Vector">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.36849 14.9382L2.26473 8.04076C2.19217 7.58819 2.3917 7.13471 2.77444 6.88258C3.15717 6.63044 3.65146 6.62591 4.03873 6.86988L7.49694 9.05383L11.1211 4.89997C11.3424 4.64602 11.6635 4.5 12 4.5C12.3364 4.5 12.6575 4.64602 12.8788 4.89997L16.503 9.05383L19.9612 6.86988C20.3485 6.62591 20.8428 6.63044 21.2255 6.88258C21.6082 7.13471 21.8078 7.58819 21.7352 8.04076L20.6314 14.9382H3.36849Z"
          fill="#E6E6E6"
        />
        <path
          d="M20.0916 18.3096L20.4135 16.2988H3.58594L3.90791 18.3096C4.03579 19.1113 4.72779 19.7008 5.53861 19.7008H18.4609C19.2717 19.7008 19.9637 19.1113 20.0916 18.3096Z"
          fill="#E6E6E6"
        />
      </g>
    </g>
  </svg>
</template>
